import { Injectable, inject } from '@angular/core'
import { AlertService } from '@core/services/alert.service'
import { SimpleStore } from '@core/states/simple-store'
import { tuiCeil } from '@taiga-ui/cdk'
import { shake } from 'radash'
import { combineLatest, debounceTime, from, of, pipe, switchMap, tap } from 'rxjs'
import { ReleaseNote } from '../models/release-note.model'
import { ReleaseNoteApiService } from '../services/release-note-api.service'

const PAGE_SIZE = 24

export interface ReleaseNoteListState {
    releaseNotes: ReleaseNote[]
    loading: boolean
    totalPages: number
    totalResults: number
    searchTerm: string
    page: number
    pageSize: number
    releaseNote: ReleaseNote | null
    id: string
}

const initialReleaseNoteListState: ReleaseNoteListState = {
    releaseNotes: [],
    loading: false,
    totalPages: 1,
    totalResults: 0,
    searchTerm: '',
    page: 1,
    pageSize: PAGE_SIZE,
    releaseNote: null,
    id: '',
}

@Injectable({
    providedIn: 'root',
})
export class ReleaseNoteListStateService extends SimpleStore<ReleaseNoteListState> {
    private releaseNoteApiService = inject(ReleaseNoteApiService)
    private alertService = inject(AlertService)

    constructor() {
        super(initialReleaseNoteListState)
        this.init()
    }

    init() {
        this.continueLoadingReleaseNote()
        this.continueResetingStateOnSearchTermChange()
    }

    private continueLoadingReleaseNote() {
        combineLatest([this.select('page'), this.select('pageSize'), this.select('searchTerm')])
            .pipe(
                debounceTime(300),
                tap(() => this.setState({ loading: true })),
                switchMap(([currentPage, pageSize, search]) => {
                    return this.releaseNoteApiService.find(
                        shake({
                            page: currentPage,
                            search,
                            size: pageSize,
                            sortBy: 'createdAt',
                            orderBy: 'desc',
                        }),
                    )
                }),
            )
            .subscribe({
                next: (data) => {
                    this.setState({
                        releaseNotes: data.data,
                        loading: false,
                        totalResults: data.meta.total,
                        totalPages: tuiCeil(data.meta.total / PAGE_SIZE),
                    })
                },
                error: (error) => {
                    this.alertService.error(error.message)
                    this.setState({ loading: false })
                },
            })
    }

    // when search term or filters change, reset pagination in order to fetch new contents
    private continueResetingStateOnSearchTermChange() {
        combineLatest([this.select('searchTerm')]).subscribe({
            next: () => {
                this.setState({
                    page: 1,
                    pageSize: PAGE_SIZE,
                    totalPages: 1,
                    totalResults: 0,
                    releaseNotes: [],
                })
            },
        })
    }

    deleteReleaseNote(id: string) {
        const { releaseNotes } = this.getState()
        this.alertService
            .confirm('Delete Article?', 'Are you sure you want to delete this Article?', 'Delete')
            .pipe(
                tap(() => {
                    const updatedReleaseNote = releaseNotes.filter((d) => d.id !== id)
                    this.setState({
                        releaseNotes: updatedReleaseNote,
                    })
                }),

                switchMap(() => this.releaseNoteApiService.deleteReleaseNote(id)),
            )
            .subscribe({
                next: () => {
                    this.alertService.success('Article deleted successfully')
                },
                error: (err) => {
                    this.alertService.error('Error while trying to delete article')
                },
            })
    }
}
